import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { BiPhone, BiMenu, BiX } from 'react-icons/bi';
import DylsLogo from 'components/landing/logo/Logo';
import { Styles } from "./styles/mobileMenu";

function MobileMenu() {
    useEffect(() => {
        // Mobile Menu
        const hmBtn = document.getElementById("mb-sidebar-btn");

        if (hmBtn) {
            const mdSidebarOverlay = document.getElementById("mb-sidebar-overlay");
            const mdSidebarBody = document.getElementById("mb-sidebar-body");
            const mdSidebarExit = document.getElementById("close-mb-sidebar");

            hmBtn.addEventListener("click", function (e) {
                e.preventDefault();
                mdSidebarOverlay.classList.toggle("visible");
                mdSidebarBody.classList.toggle("opened");
            });

            mdSidebarOverlay.addEventListener("click", function (e) {
                e.preventDefault();
                mdSidebarOverlay.classList.remove("visible");
                mdSidebarBody.classList.remove("opened");
            });

            mdSidebarExit.addEventListener("click", function (e) {
                e.preventDefault();
                mdSidebarOverlay.classList.remove("visible");
                mdSidebarBody.classList.remove("opened");
            });
        }

        // Menu Accordion -----------------
        const menuButton = document.querySelectorAll(".mb-menu-button");
        menuButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "mb-menu-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "mb-menu-content";
                    content.style.maxHeight = "0";
                }
            });
        });
    });

    return (
        <Styles>
            {/* Mobile Menu */}
            <section className="mobile-menu-area">
                <Container>
                    <Row>
                        <Col md="0" sm="12">
                            <div className="mb-topbar d-flex justify-content-between">
                                <div className="topbar-item">
                                    <p><BiPhone/>+54-342-4-868047</p>
                                </div>
                                {/* <div className="topbar-item">
                                    <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/login"}>Ingreso</Link></li>
                                        <li className="list-inline-item">/</li>
                                        <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/registration"}>Registro</Link></li>
                                    </ul>
                                </div> */}
                            </div>
                            <div className="mb-logo-area d-flex justify-content-between">
                                <div className="mb-logo-box d-flex">
                                    <div className="hm-button">
                                        <a href={process.env.PUBLIC_URL + "/"} id="mb-sidebar-btn">
                                            <BiMenu />
                                        </a>
                                    </div>
                                    <div className="mb-logo">
                                    <DylsLogo className="logo" />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Mobile Menu Sidebar */}
            <section className="mb-sidebar" id="mb-sidebar-body">
                <div className="mb-sidebar-heading d-flex justify-content-between">
                    <div><h5>Menu</h5></div>
                    <div><a href={process.env.PUBLIC_URL + "/"} id="close-mb-sidebar"><BiX /></a></div>
                </div>
                <div className="mb-sidebar-menu">
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p><Link to={process.env.PUBLIC_URL + "/"}>Inicio</Link></p>
                        </button>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p><Link className="nav-link" to={process.env.PUBLIC_URL + "/about"}>DYLS</Link></p>
                        </button>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p><Link className="nav-link" to={process.env.PUBLIC_URL + "/services"}>Servicios</Link></p>
                        </button>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p><Link to={process.env.PUBLIC_URL + "/portfolio"}>Portfolio</Link></p>
                        </button>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p><Link to={process.env.PUBLIC_URL + "/news"}>Novedades</Link></p>
                        </button>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p><Link to={process.env.PUBLIC_URL + "/contact"}>Contacto</Link></p>
                        </button>
                    </div>
                </div>
            </section>
            <div className="mb-sidebar-overlay" id="mb-sidebar-overlay"></div>
        </Styles>
    )
}

export default MobileMenu
