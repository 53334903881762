import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';

import SectionTitle from 'components/common/SectionTitle';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Styles } from './styles';

export default class Cobertura extends Component {
  render() {
    return (
      <Styles>
        <section id="cobertura" className="cobertura section-bg">
          <Container data-aos="fade-up">

            <SectionTitle
              section="Cobertura"
              title="Área de cobertura"
              span="DYLS"
              description="Por otras provincias y localidades no especificadas, solicitar información a logística@dyls.com.ar o tel: 0342-4842060. Whatsapp: +541137689598."
            />

            <Row>
              <div className="col-lg-12 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
                <ul>
                  <li>
                    <i><FaMapMarkerAlt /></i>
                    <div>
                      <h5>SANTA FE</h5>
                      <p>​Santa Fe, Santo Tomé, Coronda, Arocena, San Lorenzo, Recreo, Gdor. Candioti, Iriondo, Nelson, Llambi Campbell, Monte Vera, Constituyentes, Laguna Paiva, Esperanza, Humbolt, Rafaela, Selva, Rosario y Norte de la provincia. </p>
                    </div>
                  </li>
                  <li>
                    <i><FaMapMarkerAlt /></i>
                    <div>
                      <h5>BUENOS AIRES</h5>
                      <p>Capital Federal y Gran Buenos Aires.</p>
                    </div>
                  </li>
                  <li>
                    <i><FaMapMarkerAlt /></i>
                    <div>
                      <h5>CÓRDOBA</h5>
                      <p>Córdoba Capital. </p>
                    </div>
                  </li>
                  <li>
                    <i><FaMapMarkerAlt /></i>
                    <div>
                      <h5>RESTO DEL PAÍS</h5>
                      <p>Entre ríos, Corrientes, Misiones, Chaco, Santiago del Estero y Formosa, consultar localidades.</p>
                    </div>
                  </li>
                </ul>
              </div>
            </Row>

          </Container>
        </section>

      </Styles>
    )
  }
}
