import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IframeMaps extends Component {
  static propTypes = {
    w: PropTypes.string.isRequired,
    h: PropTypes.string.isRequired
  };
  render() {
    return (
      <iframe
        title="GoogleMap"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d762.5837416819896!2d-60.72995749259567!3d-31.572138497375487!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b5070e6860f17f%3A0xc8de678cd06349c3!2sDeposito%20y%20Log%C3%ADstica%20Santafesina!5e0!3m2!1ses-419!2sar!4v1624313330257!5m2!1ses-419!2sar"
        width={this.props.w}
        height={this.props.h}
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      />
    )
  }
}
