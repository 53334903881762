import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from "reactstrap";
import { Link } from 'react-router-dom';

class CardDeposito extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.depositos.map((deposito, key) => (

          <Col md="6" lg="4" key={key} className="d-flex align-items-stretch mb-5 mb-lg-0">
            <Card className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <CardBody>
                <h5 className="title">
                  <Link to='#'>{deposito.name}</Link>
                </h5>
                <p className="description">
                  Dom: {deposito.domicilio}
                  <br />{deposito.localidad} - CP {deposito.cp}
                  <br />Tel: {deposito.telefono}
                </p>
                <p className="description">
                 Horarios: {deposito.horario1}
                </p>

              </CardBody>
            </Card>
          </Col>
        ))}
      </React.Fragment>
    )
  }
}

CardDeposito.propTypes = {
  depositos: PropTypes.array
}

export default CardDeposito
