import React, { Component } from 'react';
import { Container } from 'reactstrap';
// import { Link } from 'react-router-dom';
// import ModalVideo from 'react-modal-video';
// import { BiPlayCircle } from 'react-icons/bi';
import { Styles } from './styles';

class HeroImage extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <Styles>
        {/* Hero Image */}
        <section id="hero" className="d-flex align-items-center">
          <Container data-aos="zoom-out" data-aos-delay="100">
            <h1>
              Bienvenido a <span>DYLS</span>
            </h1>
            <h2>Depósito y Logística Santafesina</h2>
            <div className="d-flex">
              {/* <Link to={'#aboutus'} className="btn-get-started scrollto">
                DYLS
              </Link>
              <div className="video-player">
                <ModalVideo
                  channel="youtube"
                  isOpen={this.state.isOpen}
                  videoId="jDDaplaOz7Q"
                  onClose={() => this.setState({ isOpen: false })}
                />
                <Link onClick={this.openModal} to="#" className="glightbox btn-watch-video">
                  <BiPlayCircle />
                    <span>Ver Video</span>
                </Link>
              </div> */}
              {/* <a onClick={this.openModal} href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox btn-watch-video">
              <BiPlayCircle />
                <span>Watch Video</span>
              </a> */}
            </div>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default HeroImage;
