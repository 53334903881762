import React from 'react';
import { Container } from 'reactstrap';
import { FaInstagram, FaFacebook, FaRegEnvelope, FaPhone, FaWhatsapp } from "react-icons/fa";

import { Styles } from './styles';

export default function Topbar () {
  return (
    <Styles>
      <section id="topbar" className="d-flex align-items-center">
        <Container className="d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            <i className="d-flex align-items-center">
              <FaRegEnvelope className="envelope"/>
              <a href="mailto:contact@dyls.com.ar">
                <span>contacto@dyls.com.ar</span>
              </a>
            </i>
            <i className="d-flex align-items-center ms-4">
              <FaPhone className="phone"/>
              <a href="tel:+543424842060"><span>+54-342-4-842060</span></a>
            </i>
            <i className="d-flex align-items-center ms-4">
              <FaWhatsapp className="phone"/>
              <a href="tel:+5491137689598"><span>+54-9-11-3768-9598</span></a>
            </i>
          </div>
          <div className="social-links d-none d-md-flex align-items-center">
            <a href="https://www.facebook.com/DYLS-105787071624663" className="facebook" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
            <a href="https://www.instagram.com/paezhnos" className="instagram"  target="_blank" rel="noopener noreferrer"><FaInstagram /></a>

          </div>
        </Container>
      </section>
    </Styles>
  )
}
