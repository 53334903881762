import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav, NavbarToggler, NavItem, NavLink, Container } from 'reactstrap';

import ScrollheaderNav from './scrollHeaderNav';
import Topbar from 'components/landing/topbar/Topbar';
import DylsLogo from 'components/landing/logo/Logo';
import { Bars, Close, Styles } from './styles';
import MobileMenu from 'components/common/MobileMenu';

export default class Header2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: 'hero', navheading: 'Inicio' },
        { id: 2, idnm: 'depositos', navheading: 'Depósitos' },
        { id: 3, idnm: 'aboutus', navheading: 'DYLS' },
        { id: 4, idnm: 'services', navheading: 'Servicios' },
        { id: 5, idnm: 'cobertura', navheading: 'Cobertura' },
        { id: 6, idnm: 'portfolio', navheading: 'Galería' },
        { id: 8, idnm: 'contacto', navheading: 'Contacto' },
      ],
      isOpenMenu: false,
    };
  }

  toggle = () => this.setState({ isOpenMenu: !this.state.isOpenMenu });

  render() {
    let TargetId = this.state.navItems.map((item) => {
      return item.idnm;
    });

    return (
      <Styles>
        <Topbar />
        <header id="header" className={'d-flex align-items-center ' + this.props.navClass}>
          <Container className="d-flex align-items-center justify-content-between">
            {this.props.imglight !== false ? <DylsLogo className="logo" /> : <DylsLogo className="logo-sticky" />}
            <Navbar
              id="navbar"
              className= {this.props.navClass}
            >
              <NavbarToggler onClick={this.toggle}>
                { this.state.isOpenMenu
                  ? <Close className="mobile-nav-toggle">

                  </Close>
                  : <Bars className="mobile-nav-toggle">

                    <MobileMenu />
                  </Bars>
                }

              </NavbarToggler>

              <ScrollheaderNav
                scrollTargetIds={TargetId}
                scrollDuration="300"
                headerBackground="true"
                activeNavClass="active"
              >
                <Nav>
                  {this.state.navItems.map((item, key) => (
                    <NavItem key={key} className={item.navheading === 'Inicio' ? 'scrollto active' : 'scrollto'}>
                      <NavLink href={'#' + item.idnm}> {item.navheading}</NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </ScrollheaderNav>
              <div className="ms-lg-2">
                {/* <Link to="#" className="btn btn-outline-success w-xs">
                    Ingresar
                  </Link> */}
              </div>
            </Navbar>
          </Container>
        </header>
      </Styles>
    );
  }
}

Header2.propTypes = {
  imglight: PropTypes.bool,
  navClass: PropTypes.string,
};
