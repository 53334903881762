import React from 'react';
import { Link } from 'react-router-dom';
import { BiPhone, BiEnvelope, BiMap,BiChevronRight } from 'react-icons/bi';
import { FaInstagram, FaFacebookF, FaWhatsapp } from 'react-icons/fa';

import BackToTop from 'components/common/BackToTop';

import { Styles } from "./styles";

function Footer(props) {

  return (
    <Styles>

      <footer id="footer">

        <div className="footer-top">
          <div className="container">
            <div className="row">

              <div className="col-lg-3 col-md-6 footer-contact">
                <img
                  className='img-fluid'
                  src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                  alt="DYLS"
                />
                <ul className="list-unstyled">
                    <li><BiMap />Misiones 6030, CP 3000, SF, Arg.</li>
                    <li><BiEnvelope />contacto@dyls.com.ar</li>
                    <li><BiPhone />+54 342 4842060</li>
                    <li><FaWhatsapp />+54 9 11 3768-9598</li>
                </ul>

              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Enlaces Útiles</h4>
                <ul>
                  {/* <li><BiChevronRight /> <NavLink href={"#home"}>Inicio</NavLink></li>
                  <li><BiChevronRight /> <NavLink href={"#aboutus"}>DYLS</NavLink></li>
                  <li><BiChevronRight /> <NavLink href={"#services"}>Servicios</NavLink></li> */}
                  <li><BiChevronRight /> <Link to={"conidciones"}>Condiciones del Servicio</Link></li>
                  <li><BiChevronRight /> <Link to={"privacidad"}>Política de Privacidad</Link></li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                {/* <h4>Nuestros Servicios</h4>
                <ul> TRANSPORTE
                  <li><BiChevronRight /> <NavLink href={"#services"}>Servicio</NavLink></li>
                  <li><BiChevronRight /> <NavLink href={"#services"}>Servicio</NavLink></li>
                 </ul>
                <ul> ALMACENAJE
                  <li><BiChevronRight /> <NavLink href={"#services"}>Servicio</NavLink></li>
                  <li><BiChevronRight /> <NavLink href={"#services"}>Servicio</NavLink></li>
                </ul> */}
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Nuestras Redes Sociales</h4>
                <div className="social-links mt-3">
                  <a href="https://www.facebook.com/DYLS-105787071624663" className="facebook" target="_blank" rel="noopener noreferrer">
                    <FaFacebookF />
                  </a>
                  <a href="https://www.instagram.com/paezhnos" className="instagram" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="container py-4">
          <div className="copyright">
            &copy; Copyright <strong><span>DYLS</span></strong>. Todos los derechos reservados
          </div>
          <div className="credits">

            Diseño y Hosting <a href="https://jjsoft.com.ar/">JJSoft</a>
          </div>
        </div>

        {/* Back To Top  */}
        <BackToTop
          button = {props.button}
        />
      </footer>
    </Styles>
  );
}

export default Footer
