import React, { useEffect } from 'react';
import { FaTruckMoving } from 'react-icons/fa';
import ModalImage from "react-modal-image";
import { Container, Row, Col } from "reactstrap";

import SectionTitle from "components/common/SectionTitle";
import { Styles } from './styles';
import Data from './data.json';
import Filters from './filters.json';

export default function Portfolio() {

  useEffect(() => {
    const buttons = document.getElementById("portfolio-flters").children;
    const items = document.querySelector(".portfolio-container").children;

    for (let i = 0; i < buttons.length; i++) {
      buttons[i].addEventListener("click", function () {

          for (let j = 0; j < buttons.length; j++) {
              buttons[j].classList.remove("active");
          }

          this.classList.add("active");
          const target = this.getAttribute("data-target");

          for (let k = 0; k < items.length; k++) {
              items[k].style.display = "none";

              if (items[k].getAttribute("data-id") === target) {
                  items[k].style.display = "block";
              }

              if (target === "*") {
                  items[k].style.display = "block";
              }
          }
      })
    }
  });

  return (
    <Styles>

      <section id="portfolio" className="portfolio">
        <Container data-aos="fade-up">
          <SectionTitle
            section="Galería"
            title=""
          />

          <Row data-aos="fade-up" data-aos-delay="100">
            <Col lg="12" className="d-flex justify-content-center">
              <ul id="portfolio-flters">
                <li data-target="*" className="filter">Todos</li>
                {Filters.map((filtro, key) => (
                  <li data-target={filtro.categoria} key={key} className="filter">{filtro.title}</li>
                ))}

              </ul>
            </Col>
          </Row>

          <Row className="portfolio-container" data-aos="fade-up" data-aos-delay="200">
            {
              Data.map((data, i) => (
                <Col md="6" lg="4" className={'portfolio-item filter-'+ data.categoria} key={i} data-id={data.categoria}>
                  <ModalImage
                    small={process.env.PUBLIC_URL + `/assets/images/portfolio/${data.href}`}
                    large={process.env.PUBLIC_URL + `/assets/images/portfolio/${data.href}`}
                    className="img-fluid"
                    alt={data.title}
                  />

                  <div className="portfolio-info">
                    {/* <h4>{data.title}</h4>
                    <p>{data.description}</p> */}
                    <i className="portfolio-lightbox preview-link">
                      <FaTruckMoving />
                    </i>
                  </div>
              </Col>
              ))
            }
          </Row>

        </Container>
      </section>
    </Styles>
  )

}
