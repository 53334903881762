import styled from 'styled-components';
import { Link } from "react-router-dom";

export const DylsLogoContainer = styled.a`
  display: flex;
  align-items: center;
`;

export const LogoTitle = styled.h2`
  margin: 0;
  font-size: ${({ size }) => (size ? size + "px" : "20px")};
  color: ${({ color }) => (color ? color : "#fff")};
  font-weight: 900;
  margin-left: 6px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
`;
