import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Admin from './layouts/Admin';
import Home from 'layouts/Landing';
import ScrollToTop from 'helpers/ScrollToTop';
import { GlobalStyle } from "components/common/styles/global";

function App() {
    return (
        <Router>
            <GlobalStyle />
            <ScrollToTop />
            <Routes>
                <Route path='/' element={ <Home /> } />

                <Route path='/*' element={ <Home /> } />
            </Routes>
        </Router>
    )
}

export default App;
