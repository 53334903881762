import React from 'react';

import {
  StyledLink
} from './styles';

export default function DylsLogo() {

  return (

        <StyledLink to="/" className='logo'>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
            alt="DYLS,deposito y logistica santafesina"
          />
        </StyledLink>

  );
}
