import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Styles } from "./styles/sectionTitle";

export default class SectionTitle extends Component {

  render() {
    return (
      <Styles>
        <div className="section-title">
          <h2>{this.props.section}</h2>
          <h3>{this.props.title}
            {this.props.span &&
              <span> {this.props.span}</span>
            }
          </h3>
          {this.props.subTitle &&
            <h4>{this.props.subTitle}</h4>
          }
          <p>{this.props.description}</p>
        </div>
      </Styles>
    )
  }
}

SectionTitle.propTypes = {
  section: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  span: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string
}
