import React from 'react'

import { WhatsAppWidget } from 'react-whatsapp-widget'


const WhatsApp = () => {
  return <WhatsAppWidget
    phoneNumber='5491137689598'
    sendButton='Enviar'
    companyName='DYLS Atención al Cliente'
    message='Hola! 👋🏼 ¿En qué podemos ayudarte?'
    textReplyTime='Normalmente respondemos en el día'/>
}

export default WhatsApp
