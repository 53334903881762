import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import axios from 'axios';

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      subject: '',
      message: '',
      mailSent: false,
      error: null,
    };
  }

  onSubmit = (e) => {
    const data = this.state;
    e.preventDefault();
    window.grecaptcha.ready(function() {
      window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE, {action: 'submit'}).then(function(token) {
        const state = {
          ...data,
          token: token,
          action: 'submit'
        };
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API}`,
            headers: {
              'Access-Control-Allow-Origin' : `${process.env.REACT_APP_PUBLIC_URL}`,
              'content-type': 'application/json'
            },
            data: state,
          }).then((response) => {
            if (response.data.status === 'success') {
              this.setState({
                mailSent: response.data.sent
              })
              this.resetForm();
            } else if (response.data.status === 'fail') {
              alert('Hubo un error al enviar el mail.');
            }
          });

        // Send form value as well as token to the server
      });
    });
    //
  };

  resetForm() {
    this.setState({
      name: '',
      email: '',
      subject: '',
      message: '',
      mailSent: false,
      error: null,
      token: null,
    });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <form onSubmit={this.onSubmit} className="php-email-form">
        <Row>
          <Col className="form-group">
            <input
              type="text"
              name="name"
              className="form-control"
              id="name"
              placeholder="Nombre"
              onChange={this.onChange}
              value={this.state.name}
            />
          </Col>
          <Col className="form-group">
            <input
              type="email"
              className="form-control"
              name="email"
              id="email"
              placeholder="Email"
              required
              onChange={this.onChange}
              value={this.state.email}
            />
          </Col>
        </Row>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="subject"
            id="subject"
            placeholder="Asunto"
            required
            onChange={this.onChange}
            value={this.state.subject}
          />
        </div>
        <div className="form-group">
          <textarea
            className="form-control"
            name="message"
            rows="5"
            placeholder="Mensaje"
            required
            onChange={this.onChange}
            value={this.state.message}
          ></textarea>
        </div>
        <div className="my-3">
          <div className="loading">Enviando...</div>
          <div className="error-message"></div>
          <div className="sent-message">Su mensaje fue enviado. Gracias por contactarnos!</div>
          <p className="google-text">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</ a> apply.</p>
        </div>
        <div className="text-center">
          <button type="submit">Enivar Mensaje</button>
        </div>
      </form>
    );
  }
}
