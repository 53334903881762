import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { FaTruckMoving, FaBoxes } from 'react-icons/fa';

import SectionTitle from 'components/common/SectionTitle';
import Datas from 'data/landing/servicios.json';
import { Styles } from './styles';

export default class Services extends Component {
  render() {
    return (
      <Styles>
        <section id="services" className="services">
          <Container data-aos="fade-up">
            <SectionTitle 
              section="Servicios"
              title="Nuestros "
              span="Servicios"
              subTitle="Confiabilidad, rapidéz y seguridad con seguimiento satelital"
              description="Nuestros servicios están pensados para satisfacer los más exigentes requerimientos, adecuándonos a las más diversas necesidades. Estamos preparados para transformarnos en un confiable socio estratégico y eslabón fundamental para garantizar su tranquilidad siempre. Descubra la diferencia en calidad y profesionalismo contratando nuestra amplia gama de soluciones en depósito y logística."
            />
            
            <Row>
            {
              Datas.map((data, key) =>(
                <Col key={key} lg="4" md="6" className="d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
                  <div className="icon-box">
                    <div className="icon">{ (data.categoria === 'Transporte') ? <FaTruckMoving /> : <FaBoxes /> }</div>
                    <h4><Link to="#">{data.servicio}</Link></h4>
                    <h6>{data.categoria}</h6>
                    <p className={(data.description)?'': "p-empty"}>{(data.description)?data.description:"Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."}</p>
                  </div>
                </Col>
              ))
            }
            </Row>

          </Container>
        </section>
      </Styles>
    )
  }
}
