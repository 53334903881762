import React, { Component } from 'react';
import { Container, Row } from "reactstrap";

import CardDeposito from "./card-depostio";
import Data from "data/landing/depositos.json";
import { Styles } from './styles';


export default class Depositos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      depositos: Data,
    }
  }

  render() {
    return (
      <Styles>
        <section id="depositos" className="featured-services">
          <Container data-aos="fade-up">
            <Row>
              <CardDeposito depositos={this.state.depositos} />

            </Row>
          </Container>
        </section>
      </Styles>
    )
  }
}
