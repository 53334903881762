import styled from "styled-components";
import 'react-whatsapp-widget/dist/index.css'
import { colors } from "../element/elements";

export const Styles = styled.div`
    button.back-to-top {
      position: fixed;
      visibility: hidden;
      opacity: 0;
      right: 40px;
      bottom: 95px;
      z-index: 10;
      background: #041e3f;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      transition: all 0.4s;
        i {
          font-size: 28px;
          color: ${colors.white};
          line-height: 0;
        }
        &:hover {
          background: #3284f1;
          color: ${colors.white};
        }
    }

    button.back-to-top.active {
      visibility: visible;
      opacity: 1;
    }

    .whatsapp {
      position: fixed;
      visibility: visible;
      opacity: 1;
      right: 25px;
      bottom: 25px;
      z-index: 20;

    }
`;
