import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { BiMap, BiEnvelope } from 'react-icons/bi';
import { FaWhatsapp } from 'react-icons/fa';

import Form from 'components/landing/contact/Form';
import IframeMaps from 'components/common/IframeMaps';
import SectionTitle from 'components/common/SectionTitle';
import { Styles } from './styles';

export default class Contact extends Component {

  render() {
    return (
      <Styles>
        <section id="contacto" className="contact">
          <Container data-aos="fade-up">

            <SectionTitle
              section="contacto"
              title=""
              span="Contactanos"
            />

            <Row data-aos="fade-up" data-aos-delay="100">
              <Col lg="6">
                <div className="info-box mb-4">
                  <BiMap />
                  <h3>Estamos en</h3>
                  <p>Misiones 6030 - C.P. 3000 Santa Fe - Capital</p>
                </div>
              </Col>

              <Col lg="3" md="6">
                <div className="info-box  mb-4">
                  <BiEnvelope />
                  <h3>Escribinos</h3>
                  <p>contacto@dyls.com.ar</p>
                </div>
              </Col>

              <Col lg="3" md="6">
                <div className="info-box  mb-4">
                  <FaWhatsapp />
                  <h3>LLamanos</h3>
                  <p>+54 9 11 3768-9598</p>
                </div>
              </Col>

            </Row>

            <Row data-aos="fade-up" data-aos-delay="100">

              <Col lg="6">
                <IframeMaps
                  w= '100%'
                  h= '384px'
                />
              </Col>

              <Col lg="6">
                <Form  />
                {/* <form action="forms/contact.php" method="post" role="form" className="php-email-form">

                </form> */}
              </Col>

            </Row>

          </Container>
        </section>
      </Styles>
    )
  }
}
