export const colors = {

  bg1: "#182B49",
  bg2: "#F6F9FF",
  bg3: "#ed3c0d",
  bg4: "#18d26e",
  bg5: "#f1f6fe",
  bg6: "#e7f1fd",
  gr_bg: "linear-gradient(90deg, #b0a478 0%, #a2935f 100%)",
  gr_bg2: "linear-gradient(90deg, #a2935f 0%, #b0a478 100%)",
  copy_bg: "#122340",
  blue: "#041e3f",
  blue2: "#3b8af2",
  blue3: "#0a3d80",
  blue4: "#0d6efd",
  blue5: "#3284f1",
  blue6: "#0d58ba",
  brown: "#b0a478",
  brown2: "#d9cb68",
  green: "#b0a478",
  green2: "#a2935f",
  red: "#ff6f6f",
  purple: "#84479c",
  yellow: "#fbab19",
  black1: "#182B49",
  black2: "#444444",
  black3: "#222222",
  white: "#ffffff",
  text1: "#555555",
  text2: "#666666",
  text3: "#969696",
  text4: "#aaaaaa",
  text5: "#cccccc",
  text6: "#ffffff",
  text7: "#777777",
  border1: "#eeeeee",
  border2: "#3e3e3e",
  border3: "#dddddd",
  border4: "#d4e5fc",
  border5: "#b3d1fa",
  footer1: "#1a1b25",
  footer2: "#16171f",
  ftext: "#8D8E92",
  preloader: "#e2eefd",
}

export const fonts = {

  roboto: "'Roboto', sans-serif",
  poppins: "'Poppins', sans-serif",
  opensans: "'Open Sans', sans-serif",

}
