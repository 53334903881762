import styled from 'styled-components';
import { FaBars, FaRegWindowClose } from 'react-icons/fa';

import { fonts, colors } from '../../common/element/elements';

export const Bars = styled(FaBars)`
  display: none;
  color: ${colors.white};
  @media screen and (max-width: 991px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const Close = styled(FaRegWindowClose)`
  display: none;
  color: ${colors.white};
  @media screen and (max-width: 991px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const Styles = styled.div`

  #header {
    background: ${colors.blue};
    transition: all 0.5s;
    z-index: 997;
    height: 86px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);

    .fixed-top {
      height: 70px;
    }

    .logo {
      font-size: 30px;
      margin: 0;
      padding: 0;
      line-height: 1;
      font-weight: 600;
      letter-spacing: 0.8px;
      font-family: ${fonts.poppins};

      a {
        color: ${colors.black3};

        span {
          color: ${colors.blue3};
        }
      }

      img {
        max-height: 90px;
      }
    }

    .logo-sticky {
      font-size: 20px;
      margin: 0;
      padding: 0;
      line-height: 1;
      font-weight: 500;
      letter-spacing: 0.6px;
      font-family: ${fonts.poppins};

      a {
        color: ${colors.black3};

        span {
          color: ${colors.blue3};
        }
      }

      img {
        max-height: 20px;
      }
    }
  }

  .scrolled-offset {
    margin-top: 70px;
  }

  .navbar {
    padding: 0;

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      align-items: center;

      li {
        white-space: nowrap;
        padding: 10px 0 10px 28px;

        a {
          visibility: visible;
          width: 100%;

          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: -6px;
            left: 0;
            background-color: ${colors.white};
            visibility: hidden;
            width: 0px;
            transition: all 0.3s ease-in-out 0s;
          }

          &:hover {
            color: ${colors.blue4}
          }
        }
      }
    }

    li {
      position: relative;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 3px;
      font-size: 15px;
      font-weight: 600;
      color: ${colors.white};
      white-space: nowrap;
      transition: 0.3s;
      position: relative;

      i {
        font-size: 12px;
        line-height: 0;
        margin-left: 5px;
      }

      &:hover {
        color: ${colors.blue4};

        &::before{
          visibility: visible;
          width: 100%;
        }
      }
    }

    .active{
      color: ${colors.blue4};

      &::before {
        visibility: visible;
        width: 100%;
      }
    }

    .dropdown {
      ul {
        display: block;
        position: absolute;
        left: 28px;
        top: calc(100% + 30px);
        margin: 0;
        padding: 10px 0;
        z-index: 99;
        opacity: 0;
        visibility: hidden;
        background: ${colors.white};
        box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
        transition: 0.3s;

        li {
          min-width: 200px;

          &:hover > a{
            color: ${colors.blue4};
          }
        }

        a {
          padding: 10px 20px;
          font-weight: 400;

          &:hover {
            color: ${colors.blue4};
          }

          i {
            font-size: 12px;
          }
        }

        .active {
          &:hover {
            color: ${colors.blue4};
          }
        }
      }

      &:hover > ul {
        opacity: 1;
        top: 100%;
        visibility: visible;
      }

      .dropdown ul {
        top: 0;
        left: calc(100% - 30px);
        visibility: hidden;

        &:hover > ul {
          opacity: 1;
          top: 0;
          left: 100%;
          visibility: visible;

          @media (max-width: 1366px) {
            left: -90%;
          }
        }

        @media (max-width: 1366px) {
          left: -100%;
        }
      }
    }

  }
}

.mobile-nav-toggle {
  color: ${colors.white};
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: ${colors.white};
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }
  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: ${colors.white};
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: ${colors.white};
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a {
  padding: 10px 20px;
  font-size: 15px;
  color: ${colors.white};
}

.navbar-mobile > ul > li {
  padding: 0;
}

.navbar-mobile a:hover:before, .navbar-mobile li:hover > a:before, .navbar-mobile .active:before {
  visibility: hidden;
}

.navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
  color: ${colors.blue};
}

.navbar-mobile .getstarted {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: ${colors.white};
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
  color: ${colors.blue};
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# stickymenu & modal-video
--------------------------------------------------------------*/

.ReactStickyHeader_root {
    width: 100%;
    position: relative;
    box-sizing: border-box
}

.ReactStickyHeader_root * {
    box-sizing: border-box
}

.ReactStickyHeader_fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 60
}

.ReactStickyHeader_background,.ReactStickyHeader_background-bg,.ReactStickyHeader_foreground,.ReactStickyHeader_midground {
    background-position: top;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 32
}

.ReactStickyHeader_midground {
    position: fixed;
    z-index: 20;
    transform: translateY(-100%)
}

.ReactStickyHeader_background-bg {
    z-index: 10
}

@-webkit-keyframes modal-video {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes modal-video {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes modal-video-inner {
    0% {
        transform: translateY(100px)
    }

    to {
        transform: translate(0)
    }
}

@keyframes modal-video-inner {
    0% {
        transform: translateY(100px)
    }

    to {
        transform: translate(0)
    }
}

.modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-name: modal-video;
    animation-name: modal-video;
    transition: opacity .3s ease-out
}

.modal-video-effect-exit {
    opacity: 0
}

.modal-video-effect-exit .modal-video-movie-wrap {
    transform: translateY(100px)
}

.modal-video-body {
    max-width: 940px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: table
}

.modal-video-inner {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%
}

.modal-video-movie-wrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-color: #333;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-name: modal-video-inner;
    animation-name: modal-video-inner;
    transform: translate(0);
    transition: transform .3s ease-out
}

.modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.modal-video-close-btn {
    position: absolute;
    z-index: 2;
    top: -35px;
    right: -35px;
    display: inline-block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border: none;
    background: transparent
}

.modal-video-close-btn:before {
    transform: rotate(45deg)
}

.modal-video-close-btn:after {
    transform: rotate(-45deg)
}

.modal-video-close-btn:after,.modal-video-close-btn:before {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px
}
`;
