import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';

import SectionTitle from 'components/common/SectionTitle';
import { BiStoreAlt,BiCog } from 'react-icons/bi';
import { Styles } from './styles';

export default class AboutUs extends Component {
  render() {
    return (
      <Styles>
        <section id="aboutus" className="about section-bg">
          <Container data-aos="fade-up">

            <SectionTitle
              section="DYLS"
              title="Encuentra algo más sobre "
              span="DYLS"
              description="GARANTÍA EN LOGÍSTICA Y TRANSPORTES"
            />

            <Row>
              <div className="col-lg-6" data-aos="fade-right" data-aos-delay="100">
                <img src={`${process.env.PUBLIC_URL}/assets/images/portfolio/flota1.jpeg`} className="img-fluid" alt="dyls flota transporte logistica camiones deposito" />
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
                <p className="font-italic">
                  Somos una empresa con una sólida trayectoria en el rubro transporte de cargas generales uniendo la Provincia de Santa Fe
                  con la Provincia de Buenos Aires.
                </p>
                <p>Hemos crecido con esfuerzo, dedicación y profesionalismo, en una constante búsqueda de la excelencia de nuestros
                servicios. En miras de constituirnos en la alternativa más conveniente y confiable para nuestros usuarios.</p>
                <ul>
                  <li>
                    <i><BiStoreAlt /></i>
                    <div>
                      <h5>Clientes</h5>
                      <p>Tenemos como clientes a prestigiosas firmas de diferentes rubros de nuestra región: Hogar, electrónica,
                      artículos deportivos, electricidad, zapaterías, jugueterías, ferreterías, librerías y tiendas (entre otras).</p>
                    </div>
                  </li>
                  <li>
                    <i><BiCog /></i>
                    <div>
                      <h5>Tecnología</h5>
                      <p>Contamos tecnología de última generación aplicada a todas las áreas de nuestra empresa así como con empleados idóneos,
                      para que cada envío se realice de manera rápida, segura y al costo más conveniente,
                      tenemos una moderna flota de camiones, utilitarios y maquinarias para poder llevar a cabo todas las tareas necesarias en
                      la logistica y distribucion.</p>
                    </div>
                  </li>
                </ul>
              </div>
            </Row>

          </Container>
        </section>

      </Styles>
    )
  }
}
