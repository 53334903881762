import styled from 'styled-components';

import { colors } from 'components/common/element/elements';

export const Styles = styled.div`
  #topbar{
    background: ${colors.blue};
    height: 40px;
    font-size: 14px;
    transition: all 0.5s;
    color: ${colors.white};
    padding: 0;

    .contact-info {
      i {
        font-style: normal;
        color: ${colors.white};
        padding-right: 15px;

        a {
          line-height: 0;
          transition: 0.3s;
          transition: 0.3s;
          font-size: 14px;

          &:hover {
            color: ${colors.white};
            text-decoration: underline;
          }
        }

        span {
          padding-left: 5px;
          color: ${colors.white};
        }
      }
    }

    .social-links {
      a {
        color: rgba(255, 255, 255, 0.7);
        line-height: 0;
        transition: 0.3s;
        margin-left: 20px;

        &:hover {
          color: ${colors.white};
        }
      }
    }
  }

`;
