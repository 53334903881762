import React, { Component } from 'react';
import AOS from 'aos';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';

import Header from 'components/landing/header/Header';
import Footer from 'components/landing/footer/Footer';
import HeroImage from 'components/landing/hero/HeroImage';
import Depositos from 'components/landing/depositos/Depositos';
import AboutUs from 'components/landing/about/AboutUs';
import Services from 'components/landing/services/Services';
import Cobertura from 'components/landing/cobertura/Cobertura';
import Portfolio from 'components/landing/portfolio/Portfolio';
import Contact from 'components/landing/contact/Contact';

export default class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass : "",
      button: "",
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
    AOS.init({
      duration: 1000
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true)
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "fixed-top", imglight: false })
      this.setState({ button: "active", imglight: false })
    } else {
      this.setState({ navClass: "", imglight: true })
      this.setState({ button: "", imglight: true })
    }
  }

  render() {
    return (
      <div className="main-wrapper">
        <Header
          navClass={this.state.navClass}
          imglight={this.state.imglight}
        />
        <HeroImage />

        <main id="main">

          <Depositos />
          <AboutUs />
          <Services />
          <Cobertura />
          <Portfolio />
          <Contact />

        </main>

        <Footer
          button={this.state.button}
        />

      </div>
    );
  }
}
