import styled from "styled-components";
import { colors } from "components/common/element/elements";

export const Styles = styled.div`
.cobertura .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.cobertura .content ul {
  list-style: none;
  padding: 0;
}

.cobertura .content ul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 35px;
}

.cobertura .content ul li:first-child {
  margin-top: 35px;
}

.cobertura .content ul i {
  background: ${colors.white};
  box-shadow: 0px 6px 15px rgba(16, 110, 234, 0.12);
  font-size: 24px;
  padding: 20px;
  margin-right: 15px;
  color: #041e3f;
  border-radius: 50px;
}

.cobertura .content ul h5 {
  font-size: 18px;
  color: #555555;
}

.cobertura .content ul p {
  font-size: 15px;
}

.cobertura .content p:last-child {
  margin-bottom: 0;
}

`;
